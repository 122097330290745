import { useUserStore } from "@/store/user.store";
import { app } from "@/config/firebase";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithCustomToken,
  sendPasswordResetEmail,
  signInWithCredential,
  signInWithRedirect,
  getRedirectResult,
  signInWithPopup,
} from "firebase/auth";
import { Get, Post, useFetch } from "@/composables/useFetch";
import Environment from "@/config/env";
import type ICreateTeacherPersonOnCourses from "@/interfaces/people/create-teacher-person-on-courses.interface";
import {FirebaseError} from "firebase/app";

export default class AuthService {
  private auth = getAuth(app);
  private emailRegex = Environment.EMAIL_REGEX;
  private call = useFetch();

  public async openSignWithGoogleTeacher() {
    const { data } = await this.call(new Get(), import.meta.env.VITE_AUTH_ENDPOINT ?? "authorization");
    if (!data) {
      throw new Error("No auth url generated");
    }
    location.href = data;
  }

  public async signInWithCustomToken(token: string) {
    await signInWithCustomToken(this.auth, token);
    await this.authFunction();
  }

  public async signWithGoogleRedirectStudent() {
    await signInWithRedirect(this.auth, this.googleProvider);
  }

  public async getRedirectResult() {
    await getRedirectResult(this.auth);
    await this.authFunction();
  }

  public async signWithGooglePopupStudent() {
    await signInWithPopup(this.auth, this.googleProvider);
    await this.authFunction();
  }

  private get googleProvider(): GoogleAuthProvider {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: "consent",
      access_type: "offline",
    });
    this.addScopes(provider);
    return provider;
  }

  private addScopes(provider: GoogleAuthProvider) {
    provider.addScope("email");
    provider.addScope("openid");
  }

  public async signInWithEmailAndPassword(email: string, password: string) {
    if (!this.emailRegex.test(email)) {
      const { data } = await this.findEmailByUsername(email);
      email = data;
    }
    await signInWithEmailAndPassword(this.auth, email, password);
    await this.authFunction();
  }

  public async authFunction() {
    const idToken = await this.auth.currentUser?.getIdToken();
    return this.call(new Post(), "authFunction", {
      idToken,
      isCustomToken: false
    });
  }

  public async generateMeilisearchTokenForRegister(organization_id: string) {
    return this.call(
      new Post(),
      "generateMeilisearchTokenForRegister",
      {
        organization_id,
      },
      true
    );
  }

  public async createTeacherPersonOnCourses(
    data: ICreateTeacherPersonOnCourses
  ) {
    return this.call(new Post(), "createTeacherPersonOnCourses", data, true);
  }

  public async resetPassword(email: string) {
    return sendPasswordResetEmail(this.auth, email);
  }

  public getCurrentUserEmail() {
    return this.auth.currentUser?.email;
  }

  public async signInWithCredential(accessToken: string, idToken: string) {
    const credential = GoogleAuthProvider.credential(
      idToken.replace('"', ""),
      accessToken.replace('"', "")
    );
    await signInWithCredential(this.auth, credential);
    await this.authFunction();
  }

  public static listenUserData() {
    const auth = getAuth(app);
    const userStore = useUserStore();
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        await userStore.setUserIsLoggedIn();
      } else {
        userStore.setUserIsLoggedOff();
      }
    });
  }

  public async getIdToken() {
    return this.auth.currentUser?.getIdToken();
  }

  private async findEmailByUsername(username: string) {
    return await this.call(new Get(), `findEmailByUser?username=${username}`);
  }

  public getCurrentUser() {
    return this.auth?.currentUser;
  }

  public async signOut() {
    return await this.auth.signOut();
  }
}
